import { faBullhorn, faHome, faRssSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { graphql } from "gatsby";
import { StaticQuery } from "gatsby";
import { Link } from "gatsby";
import * as React from "react";

const Navbar = class extends React.Component {

  public componentDidMount() {
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll(".navbar-burger"), 0);
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {

      // Add a click event on each of them
      $navbarBurgers.forEach(el => {
        el.addEventListener("click", () => {

        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle("is-active");
        if ($target !== null) {
          $target.classList.toggle("is-active");
        }

        });
      });
    }
  }

  public render() {
    return (
      <StaticQuery
        query={graphql`
          query Navbar {
              site {
                siteMetadata {
                  title,
                }
              }
            }
        `}
        render={data => (
          <React.Fragment>
            <nav className="navbar is-transparent" role="navigation" aria-label="main navigation" lang="en">
              <div className="container is-narrow">
                <div className="navbar-brand">
                  <Link to="/" className="navbar-item is-size-4">
                    {data.site.siteMetadata.title}
                  </Link>
                  {/* Hamburger menu */}
                  <div className="navbar-burger burger" data-target="navMenu">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <div id="navMenu" className="navbar-menu">
                  <div className="navbar-start has-text-centered">
                    <Link className="navbar-item" to="/"><span className="icon-text"><span className="icon"><FontAwesomeIcon icon={faHome} /></span><span>Home</span></span></Link>
                    <Link className="navbar-item" to="/blog"><span className="icon-text"><span className="icon"><FontAwesomeIcon icon={faBullhorn} /></span><span>Blog</span></span></Link>
                    <a className="navbar-item" href="/atom.xml"><span className="icon-text"><span className="icon"><FontAwesomeIcon icon={faRssSquare} /></span><span>Feed</span></span></a>
                  </div>
                </div>
              </div>
            </nav>
          </React.Fragment>
        )}
      />
    );
  }
};

export default Navbar;
